<template>
  <div class="navbar-item has-dropdown is-hoverable">
    <a class="navbar-link" href="/blog">Ordering Guide</a>
    <div class="navbar-dropdown">
      <div class="navbar-item"><a href="https://www.toupeewigs.com/men-hair-systems/lace-hair-system/">lace hairsystem</a></div>
      <hr class="navbar-divider">
      <div class="navbar-item"><a href="https://www.toupeewigs.com/men-hair-systems/skin-hair-systems/">Skin Hair Systems</a></div>
      <hr class="navbar-divider">
      <div class="navbar-item"><a href="https://www.toupeewigs.com/men-hair-systems/monofilament-hair-systems/">Monofilament Hair Systems</a></div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'orderGuides',
    data (){
      return {
        d: 0,
        h: 0,
        m: 0,
        s: 0,
      }
    },
    props: ['endDate'],
    methods:{

    },
    created:function () {

    },

  }
</script>
