<template>
  <div id="contact-us" class="is-hidden">
    <div style="margin-top: 1rem;padding: 0 1rem;margin-bottom: 2rem">
      <form id="contact-us-form" @submit.prevent="onSubmit">
        <div class="field">
          <div class="columns is-mobile is-multiline is-gapless is-marginless">
            <div class="column is-4-touch is-3-desktop">
              <div class="select is-fullwidth"><select v-model="gender" name="gender" class="is-radiusless" :disabled= "isSubmit"><option value="0">👩 Ms.</option> <option value="1">👨 Mr.</option></select></div>
            </div>
            <div class="column is-8-touch is-9-desktop">
              <p class="control has-icons-left has-icons-right">
                <input v-model="lastname" :disabled= "isSubmit" required name="lastname" type="text" placeholder="Your Name" class="input is-radiusless">
                <span class="icon is-small is-left"><i class="fas fa-user-circle"></i></span>
                <span class="icon is-small is-right"><i class="fas fa-check"></i></span>
              </p>
            </div>
          </div>
        </div>

        <div class="field">
          <p class="control has-icons-left has-icons-right">
            <input v-model="email" :disabled= "isSubmit" required class="input" type="email" name="email" placeholder="Email">
            <span class="icon is-small is-left"><i class="fas fa-envelope"></i></span>
            <span class="icon is-small is-right"><i class="fas fa-check"></i></span>
          </p>
        </div>

        <div class="field">
          <p class="control has-icons-left has-icons-right">
            <input v-model="whatsapp" :disabled= "isSubmit" class="input" type="text" name="whatsapp" placeholder="Whatsapp ( recommend )">
            <span class="icon is-small is-left"><i class="fab fa-whatsapp"></i></span>
            <span class="icon is-small is-right"><i class="fas fa-check"></i></span>
          </p>
        </div>

        <div class="field">
          <p class="control has-icons-left has-icons-right">
            <input v-model="telephone" :disabled= "isSubmit" class="input" type="text" name="telephone" :placeholder=" 'Phone Number ' + ((isRequiredItem('telephone'))? '( required )' : '( optional )')">
            <span class="icon is-small is-left"><i class="fas fa-phone"></i></span>
            <span class="icon is-small is-right"><i class="fas fa-check"></i></span>
          </p>
        </div>

        <div class="field"><textarea name="content" v-model="content" class="textarea" placeholder="Your message content"></textarea></div>

        <article v-show="hint" class="message" v-bind:class="{'is-danger': isDanger, 'is-info':isInfo }">
          <div class="message-body">
            {{ hint }}
          </div>
        </article>

        <div class="has-text-centered">
          <button type="reset" class="button" style="color:#808080;margin-right: 1rem"><span>RESET</span> <span class="icon"><i class="fas fa-trash-restore-alt"></i></span></button>
          <button type="submit" class="button is-light" v-bind:class="{'is-loading': isSubmit}" :disabled= "isSubmit" style="color:#000000"><span>SUBMIT</span> <span class="icon"><i class="far fa-hand-point-up"></i></span></button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  data () {
    return {
      requiredItems:['email', 'lastname', 'content'],
      sort:"",
      isDanger:false,
      isSubmit:false,
      hint:"",
      gender:"0",
      lastname:"",
      email:"",
      whatsapp:"",
      telephone:"",
      content:"",
    }
  },
  computed: {
    isInfo:function () {
      return !this.isDanger;
    }
  },
  methods:{
    submitData:function(formDataAjax){
      let _this = this;
      this.msg = '';
      formDataAjax += '&' + CPARAM + '=' + CTOKEN;
      let url = "/api/user/reg";
      $.ajax({
        url:url,
        method: "POST",
        dataType:"json",
        data:formDataAjax,
      });
      //
      let d = {who:"Customer Service", content:_this.content};
      d.id = new Date().getTime().toString();
      d.is_where = (typeof IS_WHERE !== "undefined")?IS_WHERE:1 ;
      d.from = ((parseInt(_this.gender) === 0)? 'Ms.':'Mr.') + " " +  _this.lastname;
      d.email = _this.email;
      d.whatsapp = (_this.whatsapp)?_this.whatsapp:'not inputted';
      d.telephone = (_this.telephone)?_this.telephone:'not inputted';
      d.href = window.location.href;
      d[CPARAM] = CTOKEN;
      $.ajax({
        url:'/api/msg/receive',
        method: "POST",
        dataType:"json",
        data:d,
        beforeSend:()=> _this.isSubmit = true
      }).done(function(data, textStatus) {
        if((textStatus === 'success') &&  (parseInt(data.r) === 1)){
          _this.isDanger = false;
          _this.hint = ' 😀 Thank you for your inquiry. We will respond as soon as possible generally within a few hours .';
          layui.use('layer', function(){
            layer.msg('😀 Thank you for your inquiry. We will respond as soon as possible generally within a few hours .', {time:4000});
          });
        }else{
          _this.isDanger = true;
          _this.hint = "😞 sorry process fail : " + data.msg;
        }
      })
              .always(function(d) {
                _this.isSubmit = false;
              })
    },
    onSubmit:function () {
      let _this = this;
      _this.isDanger = true;
      _this.msg = '';
      let $form = $("#contact-us-form");
      let formData = $form.serializeArray();
      let formDataAjax = $.param(formData);
      let i = 0;
      let nameStr = '';
      for (i = 0;i<formData.length;i++){
        if((formData[i].value === '') && (_this.isRequiredItem(formData[i].name)) ){
          nameStr += ("  " + formData[i].name + "  ");
        }
      }
      if(nameStr){
        _this.isDanger = true;
        _this.hint = ' 😞 please input those required form item : ' + nameStr + " . ";
        return false;
      }else{
        _this.submitData(formDataAjax);
      }
    },
    isRequiredItem:function (n) {
      return ($.inArray( n, this.requiredItems ) !== -1);
    }
  },
  created:function(){
    let _this = this;
    let href = window.location.href;
    if(href.indexOf("special/wholesale") !== -1){
      _this.sort = 'wholesale';
      _this.requiredItems.push("telephone");
    }
    UtilsUser.userInfoFromDB(d=>{
      if(! _.isNull(d)){
        _this.email = (typeof d.email !== "undefined")? d.email : "";
        _this.lastname = (typeof d.lastname !== "undefined")? d.lastname : "";
        _this.gender = (typeof d.gender !== "undefined")? d.gender : "";
        _this.whatsapp = (typeof d.whatsapp !== "undefined")? d.whatsapp : "";
        _this.telephone = (typeof d.telephone !== "undefined")? d.telephone : "";
      }
    });
  }

}

</script>


<style scoped>

</style>
