<template>
  <div id="login-sign-up2" style="display: none;min-height: 8rem">
    <section v-show="isForm">
      <div class="tabs is-boxed" style="margin-top: 1rem">
        <ul id="login-sign-up-ul" @click="userClick">
          <li id="sign-up" class="is-active">
            <a>
              <span class="icon is-small"><i class="fas fa-user" aria-hidden="true"></i></span>
              <span>Sign Up</span>
            </a>
          </li>
          <li id="sign-in">
            <a>
              <span class="icon is-small"><i class="fas fa-sign-in-alt" aria-hidden="true"></i></span>
              <span>Sign In</span>
            </a>
          </li>
        </ul>
      </div>
      <div style="margin-top: 1rem;padding: 0 1rem;margin-bottom: 2rem">
        <form id="sign-form" @submit.prevent="onSubmit">
          <div class="field" v-if="! isSignIn">

            <div class="columns is-mobile is-multiline is-gapless is-marginless">
              <div class="column is-4-touch is-3-desktop">
                <div class="select is-fullwidth"><select name="gender" class="is-radiusless" :disabled= "isSubmit"><option value="0">👩 Ms.</option> <option value="1">👨 Mr.</option></select></div>
              </div>
              <div class="column is-8-touch is-9-desktop">
                <p class="control has-icons-left has-icons-right">
                  <input v-model="lastname" :disabled= "isSubmit" required id="lastname" name="lastname" type="text" placeholder="Input Yourself's Real Last Name" class="input is-radiusless">
                  <span class="icon is-small is-left"><i class="fas fa-user-circle"></i></span>
                  <span class="icon is-small is-right"><i class="fas fa-check"></i></span>
                </p>
              </div>
            </div>
          </div>
          <div class="field">
            <p class="control has-icons-left has-icons-right">
              <input @change="isExistEmail" v-model="email" :disabled= "isSubmit" required class="input" type="email" name="email" placeholder="Email">
              <span class="icon is-small is-left"><i class="fas fa-envelope"></i></span>
              <span class="icon is-small is-right"><i class="fas fa-check"></i></span>
            </p>
          </div>

          <div class="field" v-if="! isSignIn">
            <div class="columns is-mobile is-multiline is-gapless is-marginless">
              <div class="column is-6-touch is-8-desktop">
                <p class="control has-icons-left has-icons-right">
                  <input @change="verifyEmailCaptcha(()=>{})" v-model="emailCaptcha" :disabled= "isSubmit" required  name="captcha" type="text" placeholder="email code" class="input is-radiusless">
                  <span class="icon is-small is-left"><i class="far fa-keyboard"></i></span>
                  <span class="icon is-small is-right"><i class="fas fa-check"></i></span>
                </p>
              </div>
              <div class="column is-6-touch is-4-desktop">
                <button type="button" @click="fetchEmailCaptcha" class="button is-fullwidth is-radiusless is-light" v-bind:class="{ 'is-loading': isFetchingEmailCode }">Fetch Email Code</button>
              </div>
            </div>
          </div>


          <div class="field">
            <p class="control has-icons-left has-icons-right"><input :disabled= "isSubmit" class="input" type="password" name="password" placeholder="Password">
              <span class="icon is-small is-left"><i class="fas fa-lock"></i></span>
              <span class="icon is-small is-right"><i class="fas fa-eye"></i></span>
            </p>
          </div>
          <div v-if="! isSignIn" class="field">
            <p class="control has-icons-left has-icons-right"><input :disabled= "isSubmit" required class="input" type="password" name="repeat_password" placeholder="Repeat Input Password">
              <span class="icon is-small is-left"><i class="fas fa-lock"></i></span>
              <span class="icon is-small is-right"><i class="fas fa-eye"></i></span>
            </p>
          </div>
          <article v-show="errorMsg" class="message" v-bind:class="{'is-danger': isDanger, 'is-info':isInfo }">
            <div class="message-body">
              {{ errorMsg }}
            </div>
          </article>
          <div class="has-text-centered">
            <button type="reset" class="button" style="color:#808080;margin-right: 1rem">Reset Input</button>
            <button type="submit" class="button is-light" v-bind:class="{'is-loading': isSubmit}" :disabled= "isSubmit" style="color:#000000"><span>Submit Data</span> <span class="icon"><i class="far fa-hand-point-up"></i></span></button>
          </div>
        </form>
      </div>
    </section>
    <section v-show="! isForm">
      <div class="has-text-centered" style="padding: 1rem;color:#30932b">{{ result }}</div>
    </section>
    <p v-if="isSignIn" style="margin: 1rem;text-align: right;">
      <button @click="forgotPassword" class="button is-white"><span style="color:#858585">Forgot Password ?</span></button>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Sign',
  data () {
    return {
      isDanger:true,
      isSignIn: false,
      isForm:true,
      result:"Processing ...",
      errorMsg:"",
      isSubmit:false,
      lastname:"",
      email:"",
      emailCaptcha:"",
      verifyEmailCaptchaResult:false,
      isFetchingEmailCode:false,
      hasFetchEmailCode:false,
    }
  },
  computed: {
    isInfo:function () {
      return !this.isDanger;
    }
  },
  methods:{
    forgotPassword:function(){
        layui.use('layer', function(){
            layui.layer.msg('☕ redirecting ...');
        });
        window.location.href = window.location.origin + "/user/index#user-info";
    },
    isExistEmail:function(){
      let _this = this;
      _this.verifyEmailCaptchaResult = false;
      let d = {email:this.email};
      d[CPARAM] = CTOKEN;
      $.ajax({
        url:"/api/user/is-exist-email",
        method: "POST",
        dataType:"json",
        data:d,
      })
              .done(function(data, textStatus) {
                if(textStatus === "success" ){
                  if((parseInt(data.f)) === 1 && (! _this.isSignIn)){
                    _this.isDanger = true;
                    _this.errorMsg = "😞 So sorry, you input that email '" +   _this.email + "' already exists , you can't register it again ";
                  }else if((parseInt(data.f)) === 0 && (_this.isSignIn)){
                    _this.isDanger = true;
                    _this.errorMsg = "😞 So sorry, you input that email '" +   _this.email + "' not exists , maybe you could register it at first ";
                  }
                }
              })
              .fail(function(jqXHR, textStatus, errorThrown) {
                Utils.logError({des:'ajax isExistEmail fail : ' + textStatus + errorThrown});
              });
    },
    verifyEmailCaptcha:function(callback){
      let _this = this;
      if(! this.hasFetchEmailCode){
        _this.isDanger = true;
        _this.errorMsg = " Please click that button 'Fetch Email Code' at first";
        return false;
      }else{
        _this.errorMsg = '';
      }
      let d = {code: (this.email + this.emailCaptcha)};
      d[CPARAM] = CTOKEN;
      $.ajax({
        url:"/api/open/email-code-verify",
        method: "POST",
        dataType:"json",
        data:d,
      })
              .done(function(data, textStatus) {
                if(textStatus === "success" ){
                  if((parseInt(data.r)) === 1){
                    _this.verifyEmailCaptchaResult = true;
                    _this.isDanger = false;
                    _this.errorMsg = " 😀 Email Verification Code is correct";
                  }else{
                    _this.isDanger = true;
                    _this.errorMsg = "😞 So sorry, you input that Email Verification Code '" +   _this.emailCaptcha + "' is not correct, please check that code in your newest email from aliqueen ";
                    _this.emailCaptcha = "";
                    $("input[name=captcha]").focus();
                  }
                  callback(parseInt(data.r));
                }
                else{
                  _this.emailCaptcha = "";
                  $("input[name=captcha]").focus();
                  _this.isDanger = true;
                  _this.errorMsg += "😞 So sorry, some errors occurred, maybe you could retype Email Verification Code again";
                  if(typeof data !== "undefined"){
                    Utils.logError({des:'ajax fetchEmailCaptcha fail 111 : ' + textStatus + data.msg});
                  }
                }
              })
              .fail(function(jqXHR, textStatus, errorThrown) {
                _this.isDanger = true;
                _this.errorMsg += "😞 So sorry, some errors occurred, maybe you could try again";
                Utils.logError({des:'ajax fetchEmailCaptcha fail 222 : ' + textStatus + errorThrown});
              });
    },
    fetchEmailCaptcha:function(event){
      var theBtn = event.target;
      let _this = this;
      _this.errorMsg = '';
      let lack = '';
      if(! _this.email){
        lack += 'email ';
      }
      if(! _this.lastname){
        lack += '  your last name ';
      }
      if(lack){
        _this.errorMsg = ' 😞 please input : ' + lack + ' at first ';
      }
    else{
        let d = {email:_this.email, who:_this.lastname};
        d[CPARAM] = CTOKEN;
        $.ajax({
          url:"/api/open/fetch-email-captcha",
          method: "POST",
          dataType:"json",
          data:d,
          beforeSend: function (xhr) {
           _this.isFetchingEmailCode = true;
            theBtn.disabled = true;
          },
        })
                .done(function(data, textStatus, jqXHR ) {
                  if(textStatus === "success" && (parseInt(data.r)) === 1){
                    // _this.realEmailCaptcha = data.f;
                    _this.hasFetchEmailCode = true;
                    $("input[name=captcha]").focus();
                    _this.isDanger = false;
                    _this.errorMsg += ("😀 An email with Verification Code has been sent to " + d.email + ", please check that code in your email inbox after several seconds");
                    setTimeout(function () {
                      theBtn.disabled = false;
                    }, 30);
                  }else{
                    theBtn.disabled = false;
                    _this.isDanger = true;
                    _this.errorMsg += "😞 So sorry, some errors occurred, maybe you could try again";
                    if(typeof data !== "undefined"){
                      Utils.logError({des:'ajax fetchEmailCaptcha fail : ' + textStatus + data.msg});
                    }
                  }
                  _this.isFetchingEmailCode = false;
                })
                .fail(function(jqXHR, textStatus, errorThrown) {
                  theBtn.disabled = false;
                  _this.isDanger = true;
                  _this.errorMsg += "😞 So sorry, some errors occurred, maybe you could try again";
                  Utils.logError({des:'ajax fetchEmailCaptcha fail : ' + textStatus + errorThrown});
                });
      }
    },
    userClick:function (event) {
      let _this = this;
      _this.errorMsg = '';
      //    let $t = $(event.target);
      let text = event.target.innerText;
      //     $t.parentsUntil("ul").addClass("is-active");
      $.each($("#login-sign-up-ul").children("li"), function(){
        if ($(this).hasClass("is-active")){
          $(this).removeClass("is-active");
        }
      });
      if(text === 'Sign In'){
        _this.isSignIn = true;
        $("#sign-in").addClass("is-active");
      }else{
        _this.isSignIn = false;
        $("#sign-up").addClass("is-active");
      }
      //  $t.parent().parent().addClass("is-active");
      console.log(event.target);
    },
    submitData:function(formDataAjax){
      let _this = this;
      this.errorMsg = '';
      formDataAjax += '&' + CPARAM + '=' + CTOKEN;
      let url = (_this.isSignIn) ? "/api/user/login" : "/api/user/reg";
      $.ajax({
        url:url,
        method: "POST",
        dataType:"json",
        data:formDataAjax,
        beforeSend:()=> _this.isSubmit = true
      })
              .done(function(data, textStatus, jqXHR ) {
                if((textStatus === 'success') &&  (parseInt(data.r) === 1)){
                  _this.isForm = false;
                  _this.result =  ((_this.isSignIn) ? "Sign In " : "Sign Up") +  " Successfully ! ";
                  if(data.coupon){
                    _this.result += "An coupon : " + data.coupon + " has been given to you . At the same time , we have sent you an email about this coupon";
                  }
                  userToken = data.token;
                  userEmail = data.email;
                  userLastname = data.lastname;
                  userGender = data.gender;
                  document.getElementById("user_lastname").innerText = data.lastname;
                  UtilsUser.insertUserData(data, userToken,function () {  });
                  UtilsUser.userLoginStatusChangeAfter();
                  window.setTimeout(function () {
                    if(window.sessionStorage){
                      let signPopupIndex = sessionStorage.getItem("signPopupIndex");
                      if(signPopupIndex !== null){
                        layer.close(signPopupIndex);
                        _this.isForm = true;
                      }
                    }
                  },((data.coupon)?20000:3000));
                }else{
                  _this.errorMsg = "😞 sorry process fail : " + data.msg;
                }
              })
              .fail(function(jqXHR, textStatus, errorThrown) {
                _this.isDanger = true;
                _this.errorMsg = "😞 sorry process fail : " + textStatus +  " : " + errorThrown + " error code : 562325985956";
                Utils.logError({des : textStatus +  " : " + errorThrown + " error code : 562325985956"});
              })
              .always(function() {
                _this.isSubmit = false;
              })
    },
    onSubmit:function () {
      let _this = this;
      _this.isDanger = true;
      _this.errorMsg = '';
      let formData = $( "#sign-form" ).serializeArray();
      let formDataAjax = $.param(formData);
      let password,repeat_password;
      let i = 0;
      for (i = 0;i<formData.length;i++){
        if(formData[i].value === ''){
          _this.errorMsg += ' 😞 please input those required form item ' + formData[i].name + " ; ";
        }
        if(formData[i].name === 'password'){
          password = formData[i].value.trim();
        }
        if(formData[i].name === 'repeat_password'){
          repeat_password = formData[i].value.trim();
        }
      }
      if(! _this.isSignIn){
        if(password !== repeat_password){
          _this.errorMsg += ' 😞 "password" and "repeat password" is different ; ';
        }
      }
      if(_this.errorMsg === ''){
        if( _this.isSignIn){
          _this.submitData(formDataAjax);
        }else{
          _this.verifyEmailCaptcha(function (r) {
            if(r === 1){
              _this.submitData(formDataAjax);
            }
          });
        }
      }
    }
  },
}

</script>


<style scoped>

</style>
