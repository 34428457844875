<template>
  <span class="countdown">
    <slot></slot>
    <span class="count-digit">{{ d }}</span> <span class="count-text">d</span>
    <span class="count-digit">{{ h }}</span> <span class="count-text">h</span>
    <span class="count-digit">{{ m }}</span> <span class="count-text">m</span>
    <span class="count-digit">{{ s }}</span> <span class="count-text">s</span>
  </span>
</template>
<script>
  export default {
    name: 'Countdown',
    data (){
      return {
        d: 0,
        h: 0,
        m: 0,
        s: 0,
      }
    },
    props: ['endDate'],
    methods:{
      leftTime:function (endDate){
        let left = {leftDays:0,leftHours:0,leftMinutes:0,leftSeconds:0};
        //               endDate = '2017-12-21 18:00:00';
        let endDateArr = endDate.split(" ");
        let endDateYearArr = endDateArr[0].split("-");
        let endDateYearHour = endDateArr[1].split(":");
        //    endDate = new Date(endDate);
        let endDateObj = new Date();
        endDateObj.setFullYear(endDateYearArr[0],(parseInt(endDateYearArr[1]) - 1),endDateYearArr[2]);
        endDateObj.setHours(endDateYearHour[0],endDateYearHour[1],endDateYearHour[2],0);
        endDate = endDateObj;
        let today = new Date();
        let leftSeconds =  Math.round((endDate.getTime() - (today.getTime() +  (parseInt(new Date().getTimezoneOffset() * 60 * 1000)))) / 1000); // second 595182
        let daySeconds = 24 * 60 * 60;
        let hourSeconds = 60 * 60;
        let minuteSeconds = 60;
        if(leftSeconds > 0){
          let leftDaysRaw = leftSeconds / daySeconds;
          let leftDays = Math.floor(leftDaysRaw);
          leftSeconds = leftSeconds - leftDays * daySeconds;
          if(leftSeconds === 0){
            left = {leftDays:leftDays,leftHours:0,leftMinutes:0,leftSeconds:0};
          }else{
            let leftHoursRaw = leftSeconds / hourSeconds;
            let leftHours = Math.floor(leftHoursRaw);
            leftSeconds = leftSeconds - leftHours * hourSeconds;
            if(leftSeconds === 0){
              left = {leftDays:leftDays,leftHours:leftHours,leftMinutes:0,leftSeconds:0};
            }else{
           //   let leftMinutesRaw = _.round(leftSeconds / minuteSeconds , 2);
              let leftMinutesRaw = Number.parseFloat(leftSeconds / minuteSeconds).toFixed(2);
              let leftMinutes = Math.floor(leftMinutesRaw);
              leftSeconds = leftSeconds - leftMinutes * minuteSeconds;
              left = {leftDays:leftDays,leftHours:leftHours,leftMinutes:leftMinutes,leftSeconds:leftSeconds};
            }
          }
        }
        return left;
      },
      checkTime:function (i) {
        if (i<10){
          i="0" + i
        }
        return i
      },
      startTime:function (endDate) {
        let theLeftTime = this.leftTime(endDate);
        let d = theLeftTime.leftDays;
        let h = theLeftTime.leftHours;
        let m = theLeftTime.leftMinutes;
        let s = theLeftTime.leftSeconds;
// add a zero in front of numbers<10
        d = this.checkTime(d);
        h = this.checkTime(h);
        m = this.checkTime(m);
        s = this.checkTime(s);
        this.d = d;
        this.h = h;
        this.m = m;
        this.s = s;
        if(! (d === 0 && h === 0 && m === 0 && s === 0)){
          setTimeout(()=>this.startTime(endDate),1000);
        }
      },
    },
    created:function () {
      this.startTime(this.endDate);
    },

  }
</script>
