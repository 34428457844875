import Vue from 'vue'
import App from './App.vue'
import Countdown from './components/countdown/Countdown.vue'
import orderGuides from './components/order-guides/order-guides.vue'
// import Sign from './components/sign/Sign.vue'
Vue.config.productionTip = false;
if(document.getElementById("login-sign-up1")) {
	new Vue({
		// render: h => h(Sign),
		render: h => h(App),
	}).$mount('#login-sign-up1')
}
// if(document.getElementById("countdown-ele")) {
// 	new Vue({
// 		// render: h => h(Sign),
// 		render: h => h(Countdown),
// 	}).$mount('#countdown-ele')
// }
if(document.getElementById("countdown-ele")) {
    Vue.component('countdown',Countdown);
    new Vue({
        el: '#countdown-ele',
    });
}
if(document.getElementById("order-guides-ele")) {
    Vue.component('orderGuides',orderGuides);
    new Vue({
        el: '#order-guides-ele',
    });
}


// import Vue from 'vue'
// import App from './App.vue'
//
// Vue.config.productionTip = false
//
// new Vue({
// 	render: h => h(App),
// }).$mount('#app')
