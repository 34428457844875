<template>
    <div>
        <sign></sign>
        <contact></contact>
    </div>
</template>
<script>
// import HelloWorld from './components/HelloWorld.vue'
import Sign from './components/sign/Sign.vue'
import Contact from './components/contact/Contact.vue'
export default {
  name: 'app',
  components: {
    Sign, Contact
  }
  // components: {
  //   "sign" : Sign,
  //   "contact" : Contact
  // }
}
</script>

